import {
    useState, useEffect
} from 'react'
import Header from '../componentes/Header'
import Footer from '../componentes/Footer'
import firebase from 'firebase'
// import { Link } from 'react-router-dom'
// import moment from 'moment'
import CardDestaque from '../componentes/CardDestaque'


export default function Sobre() {
    const [servicos] = useState({
        ranking: firebase.firestore().collection("ranking"),
        configuracoes: firebase.firestore().collection('configuracoes')
    })
    let [ranknigs, setRanknigs] = useState([])

    const [ranknigsBkp, setRanknigsBkp] = useState([])
    const [loading, setLoading] = useState(true)

    const [modalidades, setModalidades] = useState([])
    const [pesquisaRanking, setPesquisaRanking] = useState("")
    const [anoSelecionado, setAnoSelecionado] = useState("")
    const [modalidadeSelecionada, setModalidadeSelecionada] = useState('')
    const [situacaoSelecionada, setSituacaoSelecionada] = useState('')


    const anos = []
    const anoAtual = new Date().getFullYear()
    for (let ano = anoAtual; ano >= 2020; ano--) {
        anos.push(ano)
    }

    const mediaQuery = window.matchMedia('(max-width: 450px)');


    useEffect(() => {

        // carregar Ranking
        
        servicos.ranking.where('exibir', '==', '1').get().then(snap =>{
            const data = snap.docs.map(e => e.data())
            setRanknigs(data);
            setRanknigsBkp(data);
            setLoading(false)
        })

        // carregar Modalidade

        servicos.configuracoes.get().then(snap => {
            const idConfiguracoes = snap.docs.map(d => d.id)[0];

            servicos.configuracoes.doc(idConfiguracoes).collection('modalidades').get().then(snap => {
                const data = snap.docs.map(d => d.data())
                setModalidades(data)
            })

        })
    }, [])


    function handleAnoSelecionado(ano) {
        setAnoSelecionado(ano)

        ranknigs = ranknigsBkp;

        if (pesquisaRanking) {
            ranknigs = ranknigs.filter(ranking => {
                return ranking?.nome.toLowerCase().includes(pesquisaRanking);
            });
        }

        if (modalidadeSelecionada) {
            ranknigs = ranknigs.filter(ranking => {
                return ranking?.modalidade ? ranking.modalidade.toLowerCase().includes(modalidadeSelecionada.toLowerCase()) : false;
            });
        }

        if (situacaoSelecionada) {
            ranknigs = ranknigs.filter(ranking => {
                return ranking.status === situacaoSelecionada;
            });
        }

        let rankingsFiltrados = ranknigs;
        
        if (ano) {
            rankingsFiltrados = ranknigs.filter(ranking => {
                return ranking.ano.includes(ano);
            });
        }


        setRanknigs(rankingsFiltrados)


    }

    function handlePesquisaRanking(pesquisa) {

        setPesquisaRanking(pesquisa);
        ranknigs = ranknigsBkp;

        if (anoSelecionado) {
            ranknigs = ranknigs.filter(ranking => {
                return ranking.ano.includes(anoSelecionado);;
            });
        }

        if (modalidadeSelecionada) {
            ranknigs = ranknigs.filter(ranking => {
                return ranking?.modalidade ? ranking.modalidade.toLowerCase().includes(modalidadeSelecionada.toLowerCase()) : false;
            });
        }

        if (situacaoSelecionada) {
            ranknigs = ranknigs.filter(ranking => {
                return ranking.status === situacaoSelecionada;
            });
        }

        const rankingsFiltrados = ranknigs.filter(ranking => {
            return ranking?.nome.toLowerCase().includes(pesquisa);
        });

        setRanknigs(rankingsFiltrados)

    }

    function handleSelecionarModalidade(modalidade) {

        setModalidadeSelecionada(modalidade)
        ranknigs = ranknigsBkp;

        if (anoSelecionado) {
            ranknigs = ranknigs.filter(ranking => {
                return ranking.ano.includes(anoSelecionado);
            });
        }

        if (pesquisaRanking) {
            ranknigs = ranknigs.filter(ranking => {
                return ranking?.nome.toLowerCase().includes(pesquisaRanking);
            });
        }

        if (situacaoSelecionada) {
            ranknigs = ranknigs.filter(ranking => {
                return ranking.status === situacaoSelecionada;
            });
        }

        let rankingsFiltrados = ranknigs;
        if (modalidade) {
            rankingsFiltrados = ranknigs.filter(ranking => {
                return ranking?.modalidade ? ranking.modalidade.toLowerCase().includes(modalidade.toLowerCase()) : false;
            });
        }


        setRanknigs(rankingsFiltrados)


    }
    function handleSelecionarSituacao(situacao) {

        setSituacaoSelecionada(situacao)

        ranknigs = ranknigsBkp;

        if (anoSelecionado) {
            ranknigs = ranknigs.filter(ranking => {
                return ranking.ano.includes(anoSelecionado);
            });
        }

        if (pesquisaRanking) {
            ranknigs = ranknigs.filter(ranking => {
                return ranking?.nome.toLowerCase().includes(pesquisaRanking);
            });
        }

        if (modalidadeSelecionada) {
            ranknigs = ranknigs.filter(ranking => {
                return ranking?.modalidade ? ranking.modalidade.toLowerCase().includes(modalidadeSelecionada.toLowerCase()) : false;
            });
        }

        let rankingsFiltrados = ranknigs;

        if (situacao) {
            rankingsFiltrados = ranknigs.filter(ranking => {
                return ranking.status === situacao;
            });
        }



        setRanknigs(rankingsFiltrados)


    }

    function limparFiltros() {
        setRanknigs(ranknigsBkp)
        setAnoSelecionado('')
        setPesquisaRanking('')
        setModalidadeSelecionada('')
        setSituacaoSelecionada('')
    }

    function detalhe(id) {
        window.location = `/detalheRanking/${id}`;
    }


    return (
        <div>
            <div className="body-wrapper blank-loder">
                <Header />
                <div class="section_fullwidth">
                    <div class="headline headline_img">
                        <div class="headline__inner">
                            <div class="headline__title">
                                <h1>Ranking</h1>
                            </div>
                            <div class="headline__description">
                                <span>Acompanhe abaixo os rankings dos melhores do ano e modalidades </span>
                            </div>
                        </div>
                    </div>
                </div>
                <main className="section_default">
                    <div className="vc_row wpb_row vc_row-fluid">
                        <div className="wpb_column vc_column_container vc_col-sm-12">
                            <div className="vc_column-inner ">
                                <div className="wpb_wrapper">
                                    <div className="js-carusel-wrapp clearfix">
                                        <div className="carusel-empty-space" style={{ height: 24 }}></div>

                                        {/* filtro - Início */}
                                        <h6 style={{ marginBottom: '8px' }}>Filtrar Por: </h6>
                                        <div class="wpb_column vc_column_container vc_col-sm-10" style={{ marginBottom: '40px', boxShadow: '0 0 0.5px 0.2px' }}>

                                            <p class="contact-form-name vc_col-sm-4">
                                                <span class="wpcf7-form-control-wrap your-name">
                                                    <h6>Nome</h6>
                                                    <input
                                                        type="text" name="your-name" size="40"
                                                        class="wpcf7-form-control wpcf7-text wpcf7-validates-as-required"
                                                        aria-required="true" aria-invalid="false"
                                                        onChange={e => handlePesquisaRanking(e.target.value)}
                                                        value={pesquisaRanking}
                                                        placeholder="Digite o nome do Ranking" />
                                                </span>
                                            </p>
                                            <p class="contact-form-name vc_col-sm-2">
                                                <span class="wpcf7-form-control-wrap your-name">
                                                    <h6>Ano</h6>
                                                    <select name="select" id="select" class="form-control" onChange={e => handleAnoSelecionado(e.target.value)} value={anoSelecionado}>
                                                        <option value="">Todos </option>
                                                        <option value="permanente">Permanente </option>
                                                        {anos.map(ano => (
                                                            <option value={ano}>{ano} </option>
                                                        ))}

                                                    </select>
                                                </span>
                                            </p>

                                            <p class="contact-form-name vc_col-sm-2">
                                                <span class="wpcf7-form-control-wrap your-name">
                                                    <h6>Modalidade</h6>
                                                    <select name="select" id="select" class="form-control" onChange={e => handleSelecionarModalidade(e.target.value)} value={modalidadeSelecionada}>
                                                        <option value="">Todas </option>
                                                        {modalidades.map(modalidade => (
                                                            <option value={modalidade.descricao}>{modalidade.descricao} </option>
                                                        ))}
                                                    </select>
                                                </span>
                                            </p>
                                            <p class="contact-form-name vc_col-sm-2">
                                                <span class="wpcf7-form-control-wrap your-name">
                                                    <h6>Situação</h6>
                                                    <select name="select" id="select" class="form-control" onChange={e => handleSelecionarSituacao(e.target.value)} value={situacaoSelecionada}>
                                                        <option value="">Todas </option>
                                                        <option value="ATIVO">Em Andamento </option>
                                                        <option value="INATIVO">Finalizado</option>
                                                    </select>
                                                </span>
                                            </p>
                                            <p class="contact-form-name vc_col-sm-2">

                                                <h6 style={{ visibility: 'hidden' }}>|</h6>
                                                <button
                                                    style={{ display: 'flex', textAlign: 'center', justifyContent: 'center', alignItems: 'center', height: '39px', width: '100%', background: 'rgb(23, 162, 184)', cursor: 'pointer' }} class="package-style-1__btn btn_defoult btn_fill vc_col-sm-12" onClick={() => limparFiltros()}> Limpar
                                                </button>

                                            </p>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* filtro - fim */}
                        <div className="wpb_column vc_column_container vc_col-sm-12">
                            <div className="vc_column-inner ">
                                <div className="wpb_wrapper">
                                    <div className="js-carusel-wrapp clearfix">
                                        <div className="carusel-empty-space" style={{ height: 24 }}></div>

                                        <div className="vc_col-sm-12" style={{ marginTop: mediaQuery.matches ? '24px' : '' }}></div>

                                        {ranknigs.length > 0 ?
                                            <div className="pnchrOwlCarousel_5ff0a67aea27d  row vc_col-sm-12">
                                                {ranknigs.map((ranking) =>
                                                    <CardDestaque imagem={ranking.avatar} detalhe={detalhe} id={ranking.id} altura="440px">
                                                        <div
                                                            title={ranking.nome} className="vizit-cart-item__title" style={{ color: "#121212" }}>
                                                            <i className="fa fa-trophy" aria-hidden="true"></i>
                                                            {mediaQuery.matches ?
                                                                ranking.nome
                                                                :
                                                                `${ranking.nome.substr(0, 46)}${ranking.nome.substr(0, 46).length < 46 ? '' : '...'}`
                                                            }
                                                        </div>
                                                        <div className="ratings-widget__details-cat"><i className="fa fa-delicious" aria-hidden="true"
                                                        ></i> {ranking.modalidade ? ranking.modalidade : 'JIU-JITSU'}</div>
                                                        <p><i className="fa fa-calendar" aria-hidden="true"></i>
                                                            {ranking?.ano}
                                                        </p>
                                                        <p><i className="fa fa-circle" aria-hidden="true" style={{ color: ranking.status === 'ATIVO' ? 'green' : 'red' }}></i>
                                                            {ranking.status === 'ATIVO' ? 'Em Andamento' : 'Finalizado'}
                                                        </p>


                                                    </CardDestaque>

                                                )}
                                            </div>
                                            :
                                            <>
                                                {loading ?
                                                    <div className='vc_col-sm-12'>
                                                        <h2><i className="fa  fa-spinner" aria-hidden="true"
                                                            style={{ color: "#c21111" }}></i> Aguarde... Carregando Ranking </h2>
                                                    </div>

                                                    :
                                                    <div className='vc_col-sm-12'>
                                                        <h2>Nenhum ranking encontrado </h2>
                                                    </div>


                                                }

                                            </>
                                        }

                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </main>
                <Footer />
            </div>
        </div>
    );
}
