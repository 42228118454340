import {
    useState, useEffect
} from 'react'
import Header from '../componentes/Header'
import Footer from '../componentes/Footer'
import firebase from 'firebase'
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Grid from '@material-ui/core/Grid';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css'
import MediaQuery from 'react-responsive'
// import moment from 'moment'
import Swal from 'sweetalert2'


// import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles((theme) => ({
    card: {
        width: '49%',
        height: 120,
        display: 'flex',
    },
    heading: {
        fontSize: theme.typography.pxToRem(20),
        fontWeight: theme.typography.fontWeightRegular,
        marginLeft: 10
    },
    avatar: {
        width: 120,
        height: 120
    },
    details: {
        display: 'flex',
        flexDirection: 'column',
    },
    content: {
        flex: '1 0 auto',
    },
    paper: {
        padding: theme.spacing(1),
        textAlign: 'center',
        color: theme.palette.text.secondary,
        whiteSpace: 'nowrap',
        marginBottom: theme.spacing(1),
    },
    large: {
        width: theme.spacing(7),
        height: theme.spacing(7),
    },
    summary: {
        backgroundColor: '#4CAF50',
    }
}));

export default function Checagem() {
    const classes = useStyles();
    const [id] = useState(window.location.pathname.replace('/detalheRanking/', ''))
    const [servicos] = useState({
        usuarios: firebase.firestore().collection('usuarios'),
        rankings: firebase.firestore().collection('ranking'),
        eventos: firebase.firestore().collection('eventos'),
        inscricoes: firebase.firestore().collection('inscricoes'),
    })
    const [ranking, setRanking] = useState({})
    const [categoriasGenericas, setCategoriasGenericas] = useState([])
    const [eventos, setEventos] = useState([])
    const [indexEvento, setIndexEvento] = useState()


    useEffect(() => {

        mensagemLoading('Carregando categorias do ranking.')

        servicos.rankings.doc(id).get().then((e) => {
            setRanking(e.data())
        })

        servicos.rankings.doc(id).collection('categorias').get().then((snap) => {

            const _categorias = snap.docs.map((d) => d.data())

            setCategoriasGenericas(_categorias)
            Swal.close()

        })
    }, [])

    async function detalheCategoria(categoriaGenerica, e) {

        e.stopPropagation()

    }


  
    const ordenacaoRanking = (a, b) => {
        if (pontuacoes(a.eventos) > pontuacoes(b.eventos)) return -1;
        if (pontuacoes(a.eventos) < pontuacoes(b.eventos)) return 1;
        if (qtdMedalha(a.eventos, 'ouro') > qtdMedalha(b.eventos, 'ouro')) return -1;
        if (qtdMedalha(a.eventos, 'ouro') < qtdMedalha(b.eventos, 'ouro')) return 1;
        if (qtdMedalha(a.eventos, 'prata') > qtdMedalha(b.eventos, 'prata')) return -1;
        if (qtdMedalha(a.eventos, 'prata') < qtdMedalha(b.eventos, 'prata')) return 1;
        if (qtdMedalha(a.eventos, 'bronze') > qtdMedalha(b.eventos, 'bronze')) return -1;
        if (qtdMedalha(a.eventos, 'bronze') < qtdMedalha(b.eventos, 'bronze')) return 1;
        if (totalMedalhas(a.eventos) > totalMedalhas(b.eventos)) return -1;
        if (totalMedalhas(a.eventos) < totalMedalhas(b.eventos)) return 1;
        if (a.qtdInscricoes > b.qtdInscricoes) return -1;
        if (a.qtdInscricoes < b.qtdInscricoes) return 1;
        if (a.idade > b.idade) return -1;
        if (a.idade < b.idade) return 1;

        return 0;
    }

    const qtdMedalha = (eventos, medalha) => {
        let qtd = 0

        eventos.forEach(ev => {
            if (ev?.medalhaCtg === medalha) {
                qtd++;
            }
            if (ev.medalhaAbs === medalha) {
                qtd++;
            }
        })

        return qtd;
    }
    const totalMedalhas = (eventos) => {
        let qtd = 0

        eventos.forEach(ev => {
            if (ev?.medalhaCtg) {
                qtd++;
            }
            if (ev.medalhaAbs) {
                qtd++;
            }
        })

        return qtd;
    }

    const pontuacoes = (eventos) => {

        let _pontuacoes = 0

        eventos.forEach(ev => {
            if (ev.pontuacaoCtg) {
                _pontuacoes += ev.pontuacaoCtg
            }
            if (ev.pontuacaoAbs) {
                _pontuacoes += ev.pontuacaoAbs
            }
        })

        return _pontuacoes

    }


    function detalheEventos(atleta, index) {

        setIndexEvento(null)
        setEventos([])


        if (index === indexEvento) {
            setIndexEvento(null)
            setEventos([])
        } else {

            setIndexEvento(index)
            setEventos(atleta.eventos)
        }

    }

    function mensagemLoading(msg) {
        Swal.fire({
            title: 'Aguarde...',
            html: msg,
            timerProgressBar: true,
            didOpen: () => {
                Swal.showLoading()
            },
            showClass: {
                popup: '' // Disable the show animation
            },
            hideClass: {
                popup: '' // Disable the hide animation
            },
            customClass: {
                content: 'swal-message-2' // Apply custom class to the content
            }
        })
    }

    function mensagemInfo() {
        const texto = `
        1º Atleta com mais medalhas de ouro
        2º Atleta com mais medalhas de prata
        3º Atleta com mais medalhas de bronze
        4º Atleta com mais medalhas no total
        5º Atleta com mais participações em eventos
        6º Atleta mais velho
        `
        Swal.fire({
            title: 'Critérios de desempate',
            html: texto,
            icon: 'info',
            customClass: {
                content: 'swal-message' // Apply custom class to the content
            }
        })
    }
    function mensagemErro(msg) {
        Swal.fire('Erro', msg, 'error')
    }

    return (
        <div>
            <div className="body-wrapper blank-loder">
                <Header />

                <div class="section_fullwidth">
                    <div class="headline headline_img">
                        <div class="headline__inner">
                            <div class="headline__title">
                                <h1>Ranking {ranking && ranking.id ? ranking.nome : 'Carregando'}</h1>
                            </div>
                            <div class="headline__description">
                                <span>Confira abaixo o ranking  </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div >
                    <main >
                        <div class="vc_row wpb_row vc_row-fluid vc_custom_1492882415082 vc_column-gap-30">
                            <div class="wpb_column vc_column_container vc_col-sm-12">
                                <div class="vc_column-inner ">
                                    <div class="wpb_wrapper">
                                        <div class="wpb_text_column wpb_content_element ">

                                            <div id="ratings-widget-25" class="widget widget_ratings-widget clearfix">
                                                <div className='' style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                    <h3 className='' style={{ marginLeft: 16 }}>Resultado do Ranking {ranking && ranking.id ? ranking.nome : 'Carregando'} </h3>

                                                    <div style={{ marginRight: '33px' }} class="">
                                                        <div style={{
                                                            width: '29px', height: '29px', borderRadius: '50%', background: '#17a2b8', display: 'flex', alignItems: 'center', justifyContent: 'center',
                                                            boxShadow: '4px 4px 8px 0px rgba(0, 0, 0, 0.7)', cursor: 'pointer'
                                                        }} onClick={() => mensagemInfo()}>
                                                            <i class="fa fa-question" aria-hidden="true"></i>

                                                        </div>
                                                    </div>

                                                </div>


                                            </div>
                                            <div class="vc_col-sm-12">
                                                {categoriasGenericas.length > 0 ?
                                                    <>
                                                        {categoriasGenericas.sort((a, b) => {
                                                            if (parseInt(a.posicao) < parseInt(b.posicao))
                                                                return -1;
                                                            if (parseInt(a.posicao) > parseInt(b.posicao))
                                                                return 1;
                                                        }).map((ctgGen, index) =>
                                                            <>
                                                                <MediaQuery minWidth={1224}>
                                                                    <Accordion onClick={(e) => detalheCategoria(ctgGen, e)} >
                                                                        <AccordionSummary
                                                                            expandIcon={<ExpandMoreIcon style={{ color: '#FFF' }} />}
                                                                            aria-controls="panel1a-content"
                                                                            id="panel1a-header"
                                                                            className={classes.summary}
                                                                        >
                                                                            <p style={{ fontSize: 15, color: '#FFFF' }}>{ctgGen.nome.toUpperCase()}</p>
                                                                        </AccordionSummary>
                                                                        <AccordionDetails onClick={(e) => e.stopPropagation()}     >
                                                                            <Grid container spacing={12}>
                                                                                <div class="vc_col-sm-12">
                                                                                    <table width={'100%'}>
                                                                                        <tr>
                                                                                            <th>ATLETA</th>
                                                                                            <th>TOTAL</th>
                                                                                        </tr>
                                                                                        <tbody>
                                                                                            {ctgGen?.atletas?.length && ctgGen.atletas.sort(ordenacaoRanking).map((atleta, index) =>
                                                                                                <>
                                                                                                    <tr key={index + 1} >
                                                                                                        <td style={{ textAlign: 'left' }}>
                                                                                                            <b>{index + 1}° {atleta.nome.toUpperCase()} </b>- ( {atleta.nomeEquipe.toUpperCase()} )
                                                                                                        </td>
                                                                                                        <td onClick={() => detalheEventos(atleta, index)} style={{ cursor: 'pointer' }}><b>{pontuacoes(atleta.eventos)} pontos</b> <i class="fa fa-search" style={{ marginLeft: 10 }}></i></td>
                                                                                                    </tr>
                                                                                                    {eventos.length > 0 && indexEvento === index ?
                                                                                                        <>
                                                                                                            <table width={'100%'} style={{ backgroundColor: '#E0F8E0' }}>
                                                                                                                <tbody>
                                                                                                                    {eventos.sort((a, b) => a.evento.nome - b.evento.nome).map((item, index) =>
                                                                                                                        <tr key={index + 1} >
                                                                                                                            <td style={{ textAlign: 'left' }}>
                                                                                                                                {item.evento.nome.toUpperCase()}<br />
                                                                                                                                <p style={{ fontSize: 13, marginTop: 10 }}><b>Peso:</b> {item.pontuacaoCtg} pontos {item.medalhaCtg ? `(${item.medalhaCtg})` : ''} | <b>Absoluto:</b> {item.pontuacaoAbs} pontos {item.medalhaAbs ? `(${item.medalhaAbs})` : ''} | <b>Total: {item.pontuacaoCtg + item.pontuacaoAbs}</b></p>

                                                                                                                            </td>
                                                                                                                        </tr>
                                                                                                                    )}
                                                                                                                </tbody>
                                                                                                            </table>
                                                                                                        </>
                                                                                                        :
                                                                                                        null
                                                                                                    }

                                                                                                </>
                                                                                            )}
                                                                                            {!ctgGen?.atletas?.length && (<p>Sem pontuações para esta categoria</p>)}
                                                                                        </tbody>
                                                                                    </table>
                                                                                </div>
                                                                            </Grid>
                                                                        </AccordionDetails>
                                                                    </Accordion>
                                                                </MediaQuery>
                                                                <MediaQuery minWidth={300} maxWidth={1000}>
                                                                    <Accordion onClick={(e) => detalheCategoria(ctgGen, e)}>
                                                                        <AccordionSummary
                                                                            expandIcon={<ExpandMoreIcon style={{ color: '#FFF' }} />}
                                                                            aria-controls="panel1a-content"
                                                                            id="panel1a-header"
                                                                            className={classes.summary}
                                                                        >


                                                                            <p style={{ fontSize: 15, color: '#FFFF' }}>{ctgGen.nome.toUpperCase()}</p>
                                                                        </AccordionSummary>
                                                                        <AccordionDetails onClick={(e) => e.stopPropagation()} >
                                                                            <table width={'100%'}>
                                                                                <tr>
                                                                                    <th>ATLETA</th>
                                                                                    <th>TOTAL</th>
                                                                                </tr>
                                                                                <tbody>
                                                                                    {ctgGen?.atletas?.length && ctgGen.atletas.sort(ordenacaoRanking).map((atleta, index) =>
                                                                                        <>
                                                                                            <tr key={index + 1} >
                                                                                                <td style={{ textAlign: 'left' }}>
                                                                                                    <b>{index + 1}° {atleta.nome.toUpperCase()}</b><br />
                                                                                                    {atleta.nomeEquipe.toUpperCase()}
                                                                                                </td>
                                                                                                <td onClick={() => detalheEventos(atleta, index)} ><b>{pontuacoes(atleta.eventos)}</b> <i class="fa fa-search" style={{ marginLeft: 10 }}></i></td>
                                                                                            </tr>
                                                                                            {eventos.length > 0 && indexEvento === index ?
                                                                                                <>
                                                                                                    <table width={'100%'} style={{ backgroundColor: '#E0F8E0' }}>
                                                                                                        <tbody>
                                                                                                            {eventos.sort((a, b) => a.evento.nome - b.evento.nome).map((item, index) =>
                                                                                                                <tr key={index + 1} >
                                                                                                                    <td style={{ textAlign: 'left' }}>
                                                                                                                        {item.evento.nome.toUpperCase()}<br />
                                                                                                                        <p style={{ fontSize: 13, marginTop: 10 }}><b>Peso:</b> {item.pontuacaoCtg} pontos {item.medalhaCtg ? `(${item.medalhaCtg})` : ''} | <b>Absoluto:</b> {item.pontuacaoAbs} pontos {item.medalhaAbs ? `(${item.medalhaAbs})` : ''} | <b>Total: {item.pontuacaoCtg + item.pontuacaoAbs}</b></p>
                                                                                                                    </td>
                                                                                                                </tr>
                                                                                                            )}
                                                                                                        </tbody>
                                                                                                    </table>
                                                                                                </>
                                                                                                :
                                                                                                null
                                                                                            }


                                                                                        </>

                                                                                    )}

                                                                                    {!ctgGen?.atletas?.length && (<p>Sem pontuações para esta categoria</p>)}

                                                                                </tbody>
                                                                            </table>
                                                                        </AccordionDetails>
                                                                    </Accordion>
                                                                </MediaQuery>
                                                            </>
                                                        )}
                                                    </>
                                                    :
                                                    <h4> Sem RESULTADOS lançados </h4>
                                                }

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </main>
                </div>
                <Footer />
            </div>

        </div>
    );
}
